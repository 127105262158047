<template>
  <div class="news-wrapper">
    <navbar></navbar>
    <div class="news-inner flex" :style="{ 'height': !!is_web ? 'calc(100% - 60px)' : 'calc(100% - 56px)' }">
      <app-main></app-main>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Navbar, AppMain } from './components'

export default {
  name: 'layout',
  components: {
    Navbar,
    AppMain
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide
    }),
  }
}
</script>

<style  lang="less" scoped>
  .news-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  // 主体区域
  .news-inner {
    // transition: margin-left .28s;
    // margin-left: 220px;
  }
</style>
